.table {
    margin: 0;
    padding: 0;
    color: black;
    border: 2px solid black;
    font-size: 80%;
}




