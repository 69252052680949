.outline {
    padding: 10px;
    background-color: white;
    color: black;
    border: 2px solid black;
    border-bottom: 0px solid black;
    font-size: 80%;
}

.question {
    padding: 5px;
    background-color: white;
    color: black;
    border: 2px solid black;
    text-align: left;
    font-size: 80%;
}
  
.CardFront {
    border: 3px solid black;
    padding: 5px;
    width: 320px;
    height: 600px;
    background-image: url("../images/front.png");
}
  
.hint {
    padding: 5px;
    background-color: white;
    border: 2px solid black;
    text-align: left;
    font-size: 80%;
}
  
.CardBack {
    border: 3px solid black;
    padding: 5px;
    width: 320px;
    height: 600px;
    background-image: url("../images/back.png");
}
  
.answer {
    padding: 5px;
    background-color: white;
    border: 2px solid black;
    text-align: left;
    font-size: 80%;
}

.box {
    padding: 1px;
    margin: 5px;
    color: black;
    background: #FFF;
    border: solid 1px blue;
    border-radius: 2px;
    font-size: 80%;
}

.blockquote {
    padding: 5px;
    margin: 5px;
    box-shadow: 0px 0px 3px 3px rgba(253, 60, 126, 1);
    font-size: 80%;
}

