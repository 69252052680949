@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.App {
  text-align: center;
  padding-top: 10px;
}

.title {
  margin: 10px;
  padding: 10px;
}
