.container {
    background-color: white;
    text-align: center;
}

.category {
    display: flex;
    justify-content: center;
    background-color: lightcyan;
    cursor: pointer;
}

